
import { defineComponent } from "vue";
import store from "@/store";
import axios from "axios";
import moment from "moment";
import * as Yup from "yup";
import { ElMessage } from "element-plus";
import { useField, useForm } from "vee-validate";

declare const window: any;
export default defineComponent({
  name: "settingPage",
  setup() {
    // Define a validation schema
    const schema = Yup.object({
      oldPassword: Yup.string().required("inputOldPassword"),
      newPassword: Yup.string()
        .max(18, "maxPassword")
        .test("min newPassword", "minPassword", (newPassword) =>
          newPassword && newPassword?.length < 6 ? false : true
        )
        .test("same newPassword", "samePassword", (newPassword) =>
          oldPassword.value && newPassword == oldPassword.value ? false : true
        ),
      confirmNewPassword: Yup.string()
        .max(18, "maxPassword")
        //.test('same confirmNewPassword', 'matchConfirmPassword', confirmNewPassword => confirmNewPasswordMatch(confirmNewPassword)),
        .test(
          "same confirmNewPassword",
          "matchConfirmPassword",
          (confirmNewPassword) =>
            newPassword.value && confirmNewPassword != newPassword.value
              ? false
              : true
        ),
      email: Yup.string()
        .email("emailFormat")
        .required("requiredEmail")
        .test(
          "Unique Email",
          "usedEmail", // <- key, message
          function (value) {
            return new Promise((resolve, reject) => {
              axios
                .post(
                  `https://php.rankopen.com/plugin.php?id=api&mode=pub&tab=setting&action=usedEmail`,
                  JSON.stringify({ email: email.value, uid: store.state.uid })
                )
                .then((res) => {
                  if (res.data == "pass") {
                    resolve(true);
                  } else {
                    resolve(false);
                  }
                })
                .catch(() => {
                  resolve(false);
                });
            });
          }
        ),
    });

    // Create a form context with the validation schema
    const { errors, validate } = useForm({
      validationSchema: schema,
    });

    // No need to define rules for fields
    const { value: oldPassword, errorMessage: oldPasswordError } =
      useField<string>("oldPassword");
    const { value: newPassword, errorMessage: newPasswordError } =
      useField<string>("newPassword");
    const { value: confirmNewPassword, errorMessage: confirmNewPasswordError } =
      useField<string>("confirmNewPassword");
    const { value: email, errorMessage: emailError } =
      useField<string>("email");

    return {
      //login,
      validate,
      errors,
      oldPassword,
      oldPasswordError,
      newPassword,
      newPasswordError,
      confirmNewPassword,
      confirmNewPasswordError,
      email,
      emailError,
    };
  },
  data() {
    return {
      userProfile: [],
      area: {},
      mobile: "",
      gender: {},
      birthdayDate: "",
      practice: {},
      bio: "",
      fbid: "no",
      files: [] as any,
      url: "",
    };
  },
  watch: {
    uidValue(newVal, oldVal) {
      if (newVal.length > 0) {
        this.getUserProfile();
      }
    },
  },
  computed: {
    uidValue() {
      return store.state.uid;
    },
    getOptionPractice() {
      return [
        { label: this.$t("practiceOther"), value: 0 },
        { label: this.$t("practiceYes"), value: 1 },
        { label: this.$t("practiceNo"), value: 2 },
      ];
    },
    getOptionGender() {
      return [
        { label: this.$t("genderOther"), value: 0 },
        { label: this.$t("genderMale"), value: 1 },
        { label: this.$t("genderFemale"), value: 2 },
      ];
    },
    getOptionRegion() {
      return [
        { label: this.$t("region0"), value: 0 },
        { label: this.$t("region1"), value: 1 },
        { label: this.$t("region2"), value: 2 },
        { label: this.$t("region3"), value: 3 },
        { label: this.$t("region4"), value: 4 },
        { label: this.$t("region5"), value: 5 },
        { label: this.$t("region6"), value: 6 },
        { label: this.$t("region7"), value: 7 },
        { label: this.$t("region8"), value: 8 },
        { label: this.$t("region9"), value: 9 },
        { label: this.$t("region10"), value: 10 },
        { label: this.$t("region11"), value: 11 },
        { label: this.$t("region12"), value: 12 },
        { label: this.$t("region13"), value: 13 },
        { label: this.$t("region14"), value: 14 },
        { label: this.$t("region15"), value: 15 },
        { label: this.$t("region16"), value: 16 },
        { label: this.$t("region17"), value: 17 },
        { label: this.$t("region18"), value: 18 },
      ];
    },
  },
  mounted() {
    if (!store.state.uid) {
      ElMessage.error(this.$t("pleaseLogin"));
      this.$router.push({name: 'login'});
    }
    this.oldPassword = "";
    if (this.uidValue) {
      this.getUserProfile();
    }
  },
  methods: {
    fbconnect() {
      //Try Facebook Login
      window.facebookConnectPlugin.login(["public_profile"],(response) => {
        if (response.authResponse) {
          //Get FacebookID with accessToken from backend
          axios
            .post(
              process.env.VUE_APP_API_URL + "&mode=jwt&tab=fblogin&action=fbconnect",
              JSON.stringify({
                accessToken: response.authResponse.accessToken,
                uid: store.state.uid,
              })
            )
            .then((response) => {
              if (response.data == "exist") {
                ElMessage.error(this.$t("facebookExist"));
              } else if (response.data == "success") {
                this.fbid = "connected";
                ElMessage.success(this.$t("fbconnectSuccess"));
              } else {
                ElMessage.error(this.$t("fbconnectFail"));
              }
            });
        } else if (response) {
          //ElMessage.error(this.$t('loginFail'))
        } else {
          ElMessage.error(this.$t("fbconnectFail"));
        }
      });
    },
    saveAvatarBtn() {
      if (this.files.length != 0) {
        var formData = new FormData();
        formData.append("uid", this.uidValue);
        formData.append("file", this.files);
        axios
          .post(
            process.env.VUE_APP_API_URL + "&mode=jwt&tab=setting&action=avatar",
            formData,
            {
              params: { uid: this.uidValue },
              headers: { "Content-Type": "multipart/form-data" },
            }
          )
          .then((response) => {
            if (response.data == "success") {
              ElMessage.success(this.$t("settingSuccess"));
              location.reload();
            } else {
              ElMessage.error(this.$t("settingError"));
            }
          })
          .catch((err) => {
            ElMessage.error(this.$t("settingError"));
          });
      } else {
        ElMessage.error(this.$t("avatarNoImage"));
      }
    },
    previewFiles(event) {
      //1048576 = 1000kb
      if (event.target.files[0].size > 1048576) {
        ElMessage.error(this.$t("avatarSize"));
      } else if (
        event.target.files[0].type != "image/jpeg" &&
        event.target.files[0].type != "image/png"
      ) {
        ElMessage.error(this.$t("avatarType"));
      } else {
        this.files = event.target.files[0];
      }
      this.url = URL.createObjectURL(this.files);
    },
    getUserProfile() {
      axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=jwt&tab=setting&action=read",
          JSON.stringify({ uid: this.uidValue })
        )
        .then((response) => {
          this.userProfile = response.data;
          this.area = Number(response.data.region);
          this.gender = Number(response.data.gender);
          this.practice = Number(response.data.ifPractice);
          this.mobile = response.data.mobile;
          this.bio = response.data.bio;
          this.email = response.data.email;
          this.fbid = response.data.fbid;
          this.birthdayDate = moment(
            new Date(
              response.data.birthyear,
              response.data.birthmonth - 1,
              response.data.birthday
            )
          ).format("DD-MM-YYYY");
          this.url =
            "https://php.rankopen.com/plugin.php?id=api&mode=pub&tab=image&uid=" +
            this.uidValue;
        })
    },
    saveBtn() {
      axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=jwt&tab=setting&action=submit",
          JSON.stringify({
            uid: this.uidValue,
            mobile: this.mobile,
            region: this.area,
            gender: this.gender,
            birthday: moment(new Date(this.birthdayDate)).format("DD"),
            birthmonth: moment(new Date(this.birthdayDate)).format("MM"),
            birthyear: moment(new Date(this.birthdayDate)).year(),
            intro: this.bio,
            practice: this.practice,
          })
        )
        .then((response) => {
          if (response.data == "success") {
            ElMessage.success(this.$t("settingSuccess"));
          } else {
            ElMessage.error(this.$t("settingError"));
          }
          this.getUserProfile();
        })
        .catch((error) => {
          ElMessage.error(this.$t("settingError"));
        });
    },
    savePasswordBtn() {
      this.validate().then((values) => {
        if (values.valid) {
          axios
            .post(
              process.env.VUE_APP_API_URL + "&mode=jwt&tab=setting&action=savePassword",
              JSON.stringify({
                uid: this.uidValue,
                oldPassword: this.oldPassword,
                newPassword: this.newPassword,
                email: this.email,
              })
            )
            .then((response) => {
              if (response.data == "success") {
                ElMessage.success(this.$t("settingSuccess"));
                this.oldPassword = "";
                this.newPassword = "";
                this.confirmNewPassword = "";
              } else if (response.data == "usedEmail") {
                ElMessage.error(this.$t("usedEmail"));
              } else {
                ElMessage.error(this.$t("settingError"));
              }
              this.getUserProfile();
            })
            .catch((error) => {
              ElMessage.error(this.$t("settingError"));
            });
        }
      });
    },
  },
});
